*:focus {
  outline-color: color('off-white');
}

a {
  color: inherit;
  text-decoration: none;
  
  &:hover {
    color: inherit;
  }
}

button {
  &:hover {
    cursor: pointer;
  }
}

.cf-btn-primary {
  background: -webkit-linear-gradient(bottom, #3278C8, #2478C2);
  background: -moz-linear-gradient(bottom, #3278C8, #2478C2);
  background: -o-linear-gradient(bottom, #3278C8, #2478C2);
  background: -ms-linear-gradient(bottom, #3278C8, #2478C2);
  background: linear-gradient(to top, #3278C8, #2478C2);
}

.transition {
  transition: all $transition;
}

.block-width {
  max-width: $block-width-lg;
}

.up-hover {
  @extend .transition;

  &:hover {
    transform: translate3d(0, -0.1rem, 0);
  }

  &:active {
    transform: translate3d(0, 0, 0);
  }
}

.drop-shadow {
  box-shadow: 0 0 2.5rem 0 rgba(102, 102, 102, 0.4);
}

.block-background {
  background-image: linear-gradient(-74deg, rgb(23, 57, 99) 0%, rgb(0, 0, 0) 100%);
  background-color: black;
}
