@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css?family=Inconsolata&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }

button {
  appearance: none;
  border: none; }

.aspect-square::before {
  padding-top: 100%; }

.aspect-landscape::before {
  padding-top: 56.25%; }

.aspect-portrait::before {
  padding-top: 150%; }

[class^='aspect'],
[class*=' aspect'] {
  position: relative; }
  [class^='aspect']::before,
  [class*=' aspect']::before {
    display: block;
    content: ' ';
    width: 100%; }
  [class^='aspect'] > *,
  [class*=' aspect'] > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.bold {
  font-weight: bold; }

.regular {
  font-weight: normal; }

.italic {
  font-style: italic; }

.capitalize {
  text-transform: capitalize; }

.uppercase {
  text-transform: uppercase; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.nowrap {
  white-space: nowrap; }

.text-overflow-ellipsis {
  text-overflow: ellipsis; }

.text-overflow-clip {
  text-overflow: clip; }

.underline {
  text-decoration: underline; }

.inline {
  display: inline; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.table {
  display: table; }

.table-cell {
  display: table-cell; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.vertical-align-top {
  vertical-align: top; }

.vertical-align-middle {
  vertical-align: middle; }

.vertical-align-bottom {
  vertical-align: bottom; }

.overflow-hidden {
  overflow: hidden; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-y-hidden {
  overflow-y: hidden; }

.overflow-auto {
  overflow: auto; }

.overflow-y-scroll {
  overflow-y: scroll; }

.overflow-x-scroll {
  overflow-x: scroll; }

.overflow-y-auto {
  overflow-y: auto; }

.overflow-x-auto {
  overflow-x: auto; }

.clearfix::before {
  content: " ";
  display: table; }

.clearfix::after {
  content: " ";
  display: table;
  clear: both; }

.left {
  float: left; }

.right {
  float: right; }

.w100 {
  width: 100%; }

.h100 {
  height: 100%; }

.hauto {
  height: auto; }

.wauto {
  width: auto; }

.col {
  float: left; }

.flex-row {
  flex-direction: row; }

.flex-col {
  flex-direction: column; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-col-reverse {
  flex-direction: column-reverse; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-nowrap {
  flex-wrap: nowrap; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-center {
  align-items: center; }

.items-baseline {
  align-items: baseline; }

.items-stretch {
  align-items: stretch; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.justify-evenly {
  justify-content: space-evenly; }

.justify-stretch {
  justify-content: stretch; }

.self-start {
  align-self: flex-start; }

.self-end {
  align-self: flex-end; }

.self-center {
  align-self: center; }

.self-baseline {
  align-self: baseline; }

.self-stretch {
  align-self: stretch; }

.content-start {
  align-content: flex-start; }

.content-end {
  align-content: flex-end; }

.content-center {
  align-content: center; }

.content-between {
  align-content: space-between; }

.content-around {
  align-content: space-around; }

.content-stretch {
  align-content: stretch; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

.sticky {
  position: sticky; }

.flex-1 {
  flex: 1; }

.flex-2 {
  flex: 2; }

.flex-3 {
  flex: 3; }

.flex-none {
  flex: none; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-first {
  order: -1; }

.order-last {
  order: 9999; }

.t0 {
  top: 0; }

.r0 {
  right: 0; }

.b0 {
  bottom: 0; }

.l0 {
  left: 0; }

.z0 {
  z-index: 0; }

.z1 {
  z-index: 1; }

.z2 {
  z-index: 2; }

.z3 {
  z-index: 3; }

.circle {
  border-radius: 50%; }

.none {
  display: none; }

.hidden {
  visibility: hidden; }

.visible {
  visibility: visible; }

.pointer {
  cursor: pointer; }

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.events-none {
  pointer-events: none; }

.events-all {
  pointer-events: all; }

.fit-cover {
  object-fit: cover; }

.fit-contain {
  object-fit: contain; }

.position-top {
  object-position: top; }

.position-bottom {
  object-position: bottom; }

.bg-cover {
  background-size: cover;
  background-position: center; }

.vh100 {
  height: 100vh; }

.vw100 {
  width: 100vw; }

.opacity-0 {
  opacity: 0; }

.opacity-1 {
  opacity: 1; }

.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word; }

.list-style-none {
  list-style: none; }

.m0 {
  margin: 0rem; }

.mt0 {
  margin-top: 0rem; }

.mr0 {
  margin-right: 0rem; }

.mb0 {
  margin-bottom: 0rem; }

.ml0 {
  margin-left: 0rem; }

.mx0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.my0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.mxn0 {
  margin-left: -0rem;
  margin-right: -0rem; }

.p0 {
  padding: 0rem; }

.pt0 {
  padding-top: 0rem; }

.pr0 {
  padding-right: 0rem; }

.pb0 {
  padding-bottom: 0rem; }

.pl0 {
  padding-left: 0rem; }

.px0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.py0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.m_25 {
  margin: 0.25rem; }

.mt_25 {
  margin-top: 0.25rem; }

.mr_25 {
  margin-right: 0.25rem; }

.mb_25 {
  margin-bottom: 0.25rem; }

.ml_25 {
  margin-left: 0.25rem; }

.mx_25 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.my_25 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.mxn_25 {
  margin-left: -0.25rem;
  margin-right: -0.25rem; }

.p_25 {
  padding: 0.25rem; }

.pt_25 {
  padding-top: 0.25rem; }

.pr_25 {
  padding-right: 0.25rem; }

.pb_25 {
  padding-bottom: 0.25rem; }

.pl_25 {
  padding-left: 0.25rem; }

.px_25 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.py_25 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.m_5 {
  margin: 0.5rem; }

.mt_5 {
  margin-top: 0.5rem; }

.mr_5 {
  margin-right: 0.5rem; }

.mb_5 {
  margin-bottom: 0.5rem; }

.ml_5 {
  margin-left: 0.5rem; }

.mx_5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.my_5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.mxn_5 {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.p_5 {
  padding: 0.5rem; }

.pt_5 {
  padding-top: 0.5rem; }

.pr_5 {
  padding-right: 0.5rem; }

.pb_5 {
  padding-bottom: 0.5rem; }

.pl_5 {
  padding-left: 0.5rem; }

.px_5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.py_5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.m_75 {
  margin: 0.75rem; }

.mt_75 {
  margin-top: 0.75rem; }

.mr_75 {
  margin-right: 0.75rem; }

.mb_75 {
  margin-bottom: 0.75rem; }

.ml_75 {
  margin-left: 0.75rem; }

.mx_75 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.my_75 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.mxn_75 {
  margin-left: -0.75rem;
  margin-right: -0.75rem; }

.p_75 {
  padding: 0.75rem; }

.pt_75 {
  padding-top: 0.75rem; }

.pr_75 {
  padding-right: 0.75rem; }

.pb_75 {
  padding-bottom: 0.75rem; }

.pl_75 {
  padding-left: 0.75rem; }

.px_75 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.py_75 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.m1 {
  margin: 1rem; }

.mt1 {
  margin-top: 1rem; }

.mr1 {
  margin-right: 1rem; }

.mb1 {
  margin-bottom: 1rem; }

.ml1 {
  margin-left: 1rem; }

.mx1 {
  margin-left: 1rem;
  margin-right: 1rem; }

.my1 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mxn1 {
  margin-left: -1rem;
  margin-right: -1rem; }

.p1 {
  padding: 1rem; }

.pt1 {
  padding-top: 1rem; }

.pr1 {
  padding-right: 1rem; }

.pb1 {
  padding-bottom: 1rem; }

.pl1 {
  padding-left: 1rem; }

.px1 {
  padding-left: 1rem;
  padding-right: 1rem; }

.py1 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.m1_5 {
  margin: 1.5rem; }

.mt1_5 {
  margin-top: 1.5rem; }

.mr1_5 {
  margin-right: 1.5rem; }

.mb1_5 {
  margin-bottom: 1.5rem; }

.ml1_5 {
  margin-left: 1.5rem; }

.mx1_5 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.my1_5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.mxn1_5 {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.p1_5 {
  padding: 1.5rem; }

.pt1_5 {
  padding-top: 1.5rem; }

.pr1_5 {
  padding-right: 1.5rem; }

.pb1_5 {
  padding-bottom: 1.5rem; }

.pl1_5 {
  padding-left: 1.5rem; }

.px1_5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.py1_5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.m1_75 {
  margin: 1.75rem; }

.mt1_75 {
  margin-top: 1.75rem; }

.mr1_75 {
  margin-right: 1.75rem; }

.mb1_75 {
  margin-bottom: 1.75rem; }

.ml1_75 {
  margin-left: 1.75rem; }

.mx1_75 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.my1_75 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.mxn1_75 {
  margin-left: -1.75rem;
  margin-right: -1.75rem; }

.p1_75 {
  padding: 1.75rem; }

.pt1_75 {
  padding-top: 1.75rem; }

.pr1_75 {
  padding-right: 1.75rem; }

.pb1_75 {
  padding-bottom: 1.75rem; }

.pl1_75 {
  padding-left: 1.75rem; }

.px1_75 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.py1_75 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.m2 {
  margin: 2rem; }

.mt2 {
  margin-top: 2rem; }

.mr2 {
  margin-right: 2rem; }

.mb2 {
  margin-bottom: 2rem; }

.ml2 {
  margin-left: 2rem; }

.mx2 {
  margin-left: 2rem;
  margin-right: 2rem; }

.my2 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mxn2 {
  margin-left: -2rem;
  margin-right: -2rem; }

.p2 {
  padding: 2rem; }

.pt2 {
  padding-top: 2rem; }

.pr2 {
  padding-right: 2rem; }

.pb2 {
  padding-bottom: 2rem; }

.pl2 {
  padding-left: 2rem; }

.px2 {
  padding-left: 2rem;
  padding-right: 2rem; }

.py2 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.m2_5 {
  margin: 2.5rem; }

.mt2_5 {
  margin-top: 2.5rem; }

.mr2_5 {
  margin-right: 2.5rem; }

.mb2_5 {
  margin-bottom: 2.5rem; }

.ml2_5 {
  margin-left: 2.5rem; }

.mx2_5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.my2_5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.mxn2_5 {
  margin-left: -2.5rem;
  margin-right: -2.5rem; }

.p2_5 {
  padding: 2.5rem; }

.pt2_5 {
  padding-top: 2.5rem; }

.pr2_5 {
  padding-right: 2.5rem; }

.pb2_5 {
  padding-bottom: 2.5rem; }

.pl2_5 {
  padding-left: 2.5rem; }

.px2_5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.py2_5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.m3 {
  margin: 3rem; }

.mt3 {
  margin-top: 3rem; }

.mr3 {
  margin-right: 3rem; }

.mb3 {
  margin-bottom: 3rem; }

.ml3 {
  margin-left: 3rem; }

.mx3 {
  margin-left: 3rem;
  margin-right: 3rem; }

.my3 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.mxn3 {
  margin-left: -3rem;
  margin-right: -3rem; }

.p3 {
  padding: 3rem; }

.pt3 {
  padding-top: 3rem; }

.pr3 {
  padding-right: 3rem; }

.pb3 {
  padding-bottom: 3rem; }

.pl3 {
  padding-left: 3rem; }

.px3 {
  padding-left: 3rem;
  padding-right: 3rem; }

.py3 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.m4 {
  margin: 4rem; }

.mt4 {
  margin-top: 4rem; }

.mr4 {
  margin-right: 4rem; }

.mb4 {
  margin-bottom: 4rem; }

.ml4 {
  margin-left: 4rem; }

.mx4 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mxn4 {
  margin-left: -4rem;
  margin-right: -4rem; }

.p4 {
  padding: 4rem; }

.pt4 {
  padding-top: 4rem; }

.pr4 {
  padding-right: 4rem; }

.pb4 {
  padding-bottom: 4rem; }

.pl4 {
  padding-left: 4rem; }

.px4 {
  padding-left: 4rem;
  padding-right: 4rem; }

.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.m6 {
  margin: 6rem; }

.mt6 {
  margin-top: 6rem; }

.mr6 {
  margin-right: 6rem; }

.mb6 {
  margin-bottom: 6rem; }

.ml6 {
  margin-left: 6rem; }

.mx6 {
  margin-left: 6rem;
  margin-right: 6rem; }

.my6 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.mxn6 {
  margin-left: -6rem;
  margin-right: -6rem; }

.p6 {
  padding: 6rem; }

.pt6 {
  padding-top: 6rem; }

.pr6 {
  padding-right: 6rem; }

.pb6 {
  padding-bottom: 6rem; }

.pl6 {
  padding-left: 6rem; }

.px6 {
  padding-left: 6rem;
  padding-right: 6rem; }

.py6 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.m8 {
  margin: 8rem; }

.mt8 {
  margin-top: 8rem; }

.mr8 {
  margin-right: 8rem; }

.mb8 {
  margin-bottom: 8rem; }

.ml8 {
  margin-left: 8rem; }

.mx8 {
  margin-left: 8rem;
  margin-right: 8rem; }

.my8 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.mxn8 {
  margin-left: -8rem;
  margin-right: -8rem; }

.p8 {
  padding: 8rem; }

.pt8 {
  padding-top: 8rem; }

.pr8 {
  padding-right: 8rem; }

.pb8 {
  padding-bottom: 8rem; }

.pl8 {
  padding-left: 8rem; }

.px8 {
  padding-left: 8rem;
  padding-right: 8rem; }

.py8 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.mauto {
  margin: auto; }

.mtauto {
  margin-top: auto; }

.mrauto {
  margin-right: auto; }

.mbauto {
  margin-bottom: auto; }

.mlauto {
  margin-left: auto; }

.mxauto {
  margin-left: auto;
  margin-right: auto; }

.myauto {
  margin-top: auto;
  margin-bottom: auto; }

.mxnauto {
  margin-left: -auto;
  margin-right: -auto; }

.pauto {
  padding: auto; }

.ptauto {
  padding-top: auto; }

.prauto {
  padding-right: auto; }

.pbauto {
  padding-bottom: auto; }

.plauto {
  padding-left: auto; }

.pxauto {
  padding-left: auto;
  padding-right: auto; }

.pyauto {
  padding-top: auto;
  padding-bottom: auto; }

.col-0 {
  width: 0%; }

.off-0 {
  margin-left: 0%; }

.col-1 {
  width: 8.33333%; }

.off-1 {
  margin-left: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.off-2 {
  margin-left: 16.66667%; }

.col-3 {
  width: 25%; }

.off-3 {
  margin-left: 25%; }

.col-4 {
  width: 33.33333%; }

.off-4 {
  margin-left: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.off-5 {
  margin-left: 41.66667%; }

.col-6 {
  width: 50%; }

.off-6 {
  margin-left: 50%; }

.col-7 {
  width: 58.33333%; }

.off-7 {
  margin-left: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.off-8 {
  margin-left: 66.66667%; }

.col-9 {
  width: 75%; }

.off-9 {
  margin-left: 75%; }

.col-10 {
  width: 83.33333%; }

.off-10 {
  margin-left: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.off-11 {
  margin-left: 91.66667%; }

.col-12 {
  width: 100%; }

.off-12 {
  margin-left: 100%; }

.color-white {
  color: #FFF; }

.bg-color-white {
  background-color: #FFF; }

.border-color-white {
  border-color: #FFF; }

.color-off-white {
  color: #D8D8D8; }

.bg-color-off-white {
  background-color: #D8D8D8; }

.border-color-off-white {
  border-color: #D8D8D8; }

.color-grey-light {
  color: #f7f9fa; }

.bg-color-grey-light {
  background-color: #f7f9fa; }

.border-color-grey-light {
  border-color: #f7f9fa; }

.color-grey-lightest {
  color: #f9f9f9; }

.bg-color-grey-lightest {
  background-color: #f9f9f9; }

.border-color-grey-lightest {
  border-color: #f9f9f9; }

.color-grey {
  color: #C5C9CD; }

.bg-color-grey {
  background-color: #C5C9CD; }

.border-color-grey {
  border-color: #C5C9CD; }

.color-blue-grey-light {
  color: #F2F8FB; }

.bg-color-blue-grey-light {
  background-color: #F2F8FB; }

.border-color-blue-grey-light {
  border-color: #F2F8FB; }

.color-blue-grey {
  color: #DBE3E7; }

.bg-color-blue-grey {
  background-color: #DBE3E7; }

.border-color-blue-grey {
  border-color: #DBE3E7; }

.color-black {
  color: #000; }

.bg-color-black {
  background-color: #000; }

.border-color-black {
  border-color: #000; }

.color-brown {
  color: #3E2C0F; }

.bg-color-brown {
  background-color: #3E2C0F; }

.border-color-brown {
  border-color: #3E2C0F; }

.color-green {
  color: #0EB880; }

.bg-color-green {
  background-color: #0EB880; }

.border-color-green {
  border-color: #0EB880; }

.color-yellow {
  color: #FFD85F; }

.bg-color-yellow {
  background-color: #FFD85F; }

.border-color-yellow {
  border-color: #FFD85F; }

.color-yellow-light {
  color: #FFE492; }

.bg-color-yellow-light {
  background-color: #FFE492; }

.border-color-yellow-light {
  border-color: #FFE492; }

@media (min-width: 640px) {
  .sm\:wauto {
    width: auto; }
  .sm\:hauto {
    height: auto; }
  .sm\:text-left {
    text-align: left; }
  .sm\:text-right {
    text-align: right; }
  .sm\:text-center {
    text-align: center; }
  .sm\:text-justify {
    text-align: justify; }
  .sm\:inline {
    display: inline; }
  .sm\:block {
    display: block; }
  .sm\:inline-block {
    display: inline-block; }
  .sm\:table {
    display: table; }
  .sm\:table-cell {
    display: table-cell; }
  .sm\:flex {
    display: flex; }
  .sm\:inline-flex {
    display: inline-flex; }
  .sm\:vertical-align-top {
    vertical-align: top; }
  .sm\:vertical-align-middle {
    vertical-align: middle; }
  .sm\:vertical-align-bottom {
    vertical-align: bottom; }
  .sm\:absolute {
    position: absolute; }
  .sm\:relative {
    position: relative; }
  .sm\:fixed {
    position: fixed; }
  .sm\:sticky {
    position: sticky; }
  .sm\:left {
    float: left; }
  .sm\:right {
    float: right; }
  .sm\:flex-row {
    flex-direction: row; }
  .sm\:flex-col {
    flex-direction: column; }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse; }
  .sm\:flex-col-reverse {
    flex-direction: column-reverse; }
  .sm\:flex-wrap {
    flex-wrap: wrap; }
  .sm\:flex-nowrap {
    flex-wrap: nowrap; }
  .sm\:items-start {
    align-items: flex-start; }
  .sm\:items-end {
    align-items: flex-end; }
  .sm\:items-center {
    align-items: center; }
  .sm\:items-baseline {
    align-items: baseline; }
  .sm\:items-stretch {
    align-items: stretch; }
  .sm\:justify-start {
    justify-content: flex-start; }
  .sm\:justify-end {
    justify-content: flex-end; }
  .sm\:justify-center {
    justify-content: center; }
  .sm\:justify-between {
    justify-content: space-between; }
  .sm\:justify-around {
    justify-content: space-around; }
  .sm\:justify-evenly {
    justify-content: space-evenly; }
  .sm\:justify-stretch {
    justify-content: stretch; }
  .sm\:flex-1 {
    flex: 1; }
  .sm\:flex-2 {
    flex: 2; }
  .sm\:flex-3 {
    flex: 3; }
  .sm\:flex-none {
    flex: none; }
  .sm\:order-0 {
    order: 0; }
  .sm\:order-1 {
    order: 1; }
  .sm\:order-2 {
    order: 2; }
  .sm\:order-3 {
    order: 3; }
  .sm\:order-first {
    order: -1; }
  .sm\:order-last {
    order: 9999; }
  .sm\:none {
    display: none; }
  .sm\:m0 {
    margin: 0rem; }
  .sm\:mt0 {
    margin-top: 0rem; }
  .sm\:mr0 {
    margin-right: 0rem; }
  .sm\:mb0 {
    margin-bottom: 0rem; }
  .sm\:ml0 {
    margin-left: 0rem; }
  .sm\:mx0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .sm\:my0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .sm\:mxn0 {
    margin-left: -0rem;
    margin-right: -0rem; }
  .sm\:p0 {
    padding: 0rem; }
  .sm\:pt0 {
    padding-top: 0rem; }
  .sm\:pr0 {
    padding-right: 0rem; }
  .sm\:pb0 {
    padding-bottom: 0rem; }
  .sm\:pl0 {
    padding-left: 0rem; }
  .sm\:px0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .sm\:py0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .sm\:m_25 {
    margin: 0.25rem; }
  .sm\:mt_25 {
    margin-top: 0.25rem; }
  .sm\:mr_25 {
    margin-right: 0.25rem; }
  .sm\:mb_25 {
    margin-bottom: 0.25rem; }
  .sm\:ml_25 {
    margin-left: 0.25rem; }
  .sm\:mx_25 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .sm\:my_25 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .sm\:mxn_25 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .sm\:p_25 {
    padding: 0.25rem; }
  .sm\:pt_25 {
    padding-top: 0.25rem; }
  .sm\:pr_25 {
    padding-right: 0.25rem; }
  .sm\:pb_25 {
    padding-bottom: 0.25rem; }
  .sm\:pl_25 {
    padding-left: 0.25rem; }
  .sm\:px_25 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .sm\:py_25 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .sm\:m_5 {
    margin: 0.5rem; }
  .sm\:mt_5 {
    margin-top: 0.5rem; }
  .sm\:mr_5 {
    margin-right: 0.5rem; }
  .sm\:mb_5 {
    margin-bottom: 0.5rem; }
  .sm\:ml_5 {
    margin-left: 0.5rem; }
  .sm\:mx_5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .sm\:my_5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .sm\:mxn_5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .sm\:p_5 {
    padding: 0.5rem; }
  .sm\:pt_5 {
    padding-top: 0.5rem; }
  .sm\:pr_5 {
    padding-right: 0.5rem; }
  .sm\:pb_5 {
    padding-bottom: 0.5rem; }
  .sm\:pl_5 {
    padding-left: 0.5rem; }
  .sm\:px_5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .sm\:py_5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .sm\:m_75 {
    margin: 0.75rem; }
  .sm\:mt_75 {
    margin-top: 0.75rem; }
  .sm\:mr_75 {
    margin-right: 0.75rem; }
  .sm\:mb_75 {
    margin-bottom: 0.75rem; }
  .sm\:ml_75 {
    margin-left: 0.75rem; }
  .sm\:mx_75 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .sm\:my_75 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .sm\:mxn_75 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .sm\:p_75 {
    padding: 0.75rem; }
  .sm\:pt_75 {
    padding-top: 0.75rem; }
  .sm\:pr_75 {
    padding-right: 0.75rem; }
  .sm\:pb_75 {
    padding-bottom: 0.75rem; }
  .sm\:pl_75 {
    padding-left: 0.75rem; }
  .sm\:px_75 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .sm\:py_75 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .sm\:m1 {
    margin: 1rem; }
  .sm\:mt1 {
    margin-top: 1rem; }
  .sm\:mr1 {
    margin-right: 1rem; }
  .sm\:mb1 {
    margin-bottom: 1rem; }
  .sm\:ml1 {
    margin-left: 1rem; }
  .sm\:mx1 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .sm\:my1 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .sm\:mxn1 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .sm\:p1 {
    padding: 1rem; }
  .sm\:pt1 {
    padding-top: 1rem; }
  .sm\:pr1 {
    padding-right: 1rem; }
  .sm\:pb1 {
    padding-bottom: 1rem; }
  .sm\:pl1 {
    padding-left: 1rem; }
  .sm\:px1 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .sm\:py1 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .sm\:m1_5 {
    margin: 1.5rem; }
  .sm\:mt1_5 {
    margin-top: 1.5rem; }
  .sm\:mr1_5 {
    margin-right: 1.5rem; }
  .sm\:mb1_5 {
    margin-bottom: 1.5rem; }
  .sm\:ml1_5 {
    margin-left: 1.5rem; }
  .sm\:mx1_5 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .sm\:my1_5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .sm\:mxn1_5 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .sm\:p1_5 {
    padding: 1.5rem; }
  .sm\:pt1_5 {
    padding-top: 1.5rem; }
  .sm\:pr1_5 {
    padding-right: 1.5rem; }
  .sm\:pb1_5 {
    padding-bottom: 1.5rem; }
  .sm\:pl1_5 {
    padding-left: 1.5rem; }
  .sm\:px1_5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .sm\:py1_5 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .sm\:m1_75 {
    margin: 1.75rem; }
  .sm\:mt1_75 {
    margin-top: 1.75rem; }
  .sm\:mr1_75 {
    margin-right: 1.75rem; }
  .sm\:mb1_75 {
    margin-bottom: 1.75rem; }
  .sm\:ml1_75 {
    margin-left: 1.75rem; }
  .sm\:mx1_75 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .sm\:my1_75 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .sm\:mxn1_75 {
    margin-left: -1.75rem;
    margin-right: -1.75rem; }
  .sm\:p1_75 {
    padding: 1.75rem; }
  .sm\:pt1_75 {
    padding-top: 1.75rem; }
  .sm\:pr1_75 {
    padding-right: 1.75rem; }
  .sm\:pb1_75 {
    padding-bottom: 1.75rem; }
  .sm\:pl1_75 {
    padding-left: 1.75rem; }
  .sm\:px1_75 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .sm\:py1_75 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .sm\:m2 {
    margin: 2rem; }
  .sm\:mt2 {
    margin-top: 2rem; }
  .sm\:mr2 {
    margin-right: 2rem; }
  .sm\:mb2 {
    margin-bottom: 2rem; }
  .sm\:ml2 {
    margin-left: 2rem; }
  .sm\:mx2 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .sm\:my2 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .sm\:mxn2 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .sm\:p2 {
    padding: 2rem; }
  .sm\:pt2 {
    padding-top: 2rem; }
  .sm\:pr2 {
    padding-right: 2rem; }
  .sm\:pb2 {
    padding-bottom: 2rem; }
  .sm\:pl2 {
    padding-left: 2rem; }
  .sm\:px2 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .sm\:py2 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .sm\:m2_5 {
    margin: 2.5rem; }
  .sm\:mt2_5 {
    margin-top: 2.5rem; }
  .sm\:mr2_5 {
    margin-right: 2.5rem; }
  .sm\:mb2_5 {
    margin-bottom: 2.5rem; }
  .sm\:ml2_5 {
    margin-left: 2.5rem; }
  .sm\:mx2_5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .sm\:my2_5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .sm\:mxn2_5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .sm\:p2_5 {
    padding: 2.5rem; }
  .sm\:pt2_5 {
    padding-top: 2.5rem; }
  .sm\:pr2_5 {
    padding-right: 2.5rem; }
  .sm\:pb2_5 {
    padding-bottom: 2.5rem; }
  .sm\:pl2_5 {
    padding-left: 2.5rem; }
  .sm\:px2_5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .sm\:py2_5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .sm\:m3 {
    margin: 3rem; }
  .sm\:mt3 {
    margin-top: 3rem; }
  .sm\:mr3 {
    margin-right: 3rem; }
  .sm\:mb3 {
    margin-bottom: 3rem; }
  .sm\:ml3 {
    margin-left: 3rem; }
  .sm\:mx3 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .sm\:my3 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .sm\:mxn3 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .sm\:p3 {
    padding: 3rem; }
  .sm\:pt3 {
    padding-top: 3rem; }
  .sm\:pr3 {
    padding-right: 3rem; }
  .sm\:pb3 {
    padding-bottom: 3rem; }
  .sm\:pl3 {
    padding-left: 3rem; }
  .sm\:px3 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .sm\:py3 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .sm\:m4 {
    margin: 4rem; }
  .sm\:mt4 {
    margin-top: 4rem; }
  .sm\:mr4 {
    margin-right: 4rem; }
  .sm\:mb4 {
    margin-bottom: 4rem; }
  .sm\:ml4 {
    margin-left: 4rem; }
  .sm\:mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .sm\:my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .sm\:mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .sm\:p4 {
    padding: 4rem; }
  .sm\:pt4 {
    padding-top: 4rem; }
  .sm\:pr4 {
    padding-right: 4rem; }
  .sm\:pb4 {
    padding-bottom: 4rem; }
  .sm\:pl4 {
    padding-left: 4rem; }
  .sm\:px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .sm\:py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .sm\:m6 {
    margin: 6rem; }
  .sm\:mt6 {
    margin-top: 6rem; }
  .sm\:mr6 {
    margin-right: 6rem; }
  .sm\:mb6 {
    margin-bottom: 6rem; }
  .sm\:ml6 {
    margin-left: 6rem; }
  .sm\:mx6 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .sm\:my6 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .sm\:mxn6 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .sm\:p6 {
    padding: 6rem; }
  .sm\:pt6 {
    padding-top: 6rem; }
  .sm\:pr6 {
    padding-right: 6rem; }
  .sm\:pb6 {
    padding-bottom: 6rem; }
  .sm\:pl6 {
    padding-left: 6rem; }
  .sm\:px6 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .sm\:py6 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .sm\:m8 {
    margin: 8rem; }
  .sm\:mt8 {
    margin-top: 8rem; }
  .sm\:mr8 {
    margin-right: 8rem; }
  .sm\:mb8 {
    margin-bottom: 8rem; }
  .sm\:ml8 {
    margin-left: 8rem; }
  .sm\:mx8 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .sm\:my8 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .sm\:mxn8 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .sm\:p8 {
    padding: 8rem; }
  .sm\:pt8 {
    padding-top: 8rem; }
  .sm\:pr8 {
    padding-right: 8rem; }
  .sm\:pb8 {
    padding-bottom: 8rem; }
  .sm\:pl8 {
    padding-left: 8rem; }
  .sm\:px8 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .sm\:py8 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .sm\:mauto {
    margin: auto; }
  .sm\:mtauto {
    margin-top: auto; }
  .sm\:mrauto {
    margin-right: auto; }
  .sm\:mbauto {
    margin-bottom: auto; }
  .sm\:mlauto {
    margin-left: auto; }
  .sm\:mxauto {
    margin-left: auto;
    margin-right: auto; }
  .sm\:myauto {
    margin-top: auto;
    margin-bottom: auto; }
  .sm\:mxnauto {
    margin-left: -auto;
    margin-right: -auto; }
  .sm\:pauto {
    padding: auto; }
  .sm\:ptauto {
    padding-top: auto; }
  .sm\:prauto {
    padding-right: auto; }
  .sm\:pbauto {
    padding-bottom: auto; }
  .sm\:plauto {
    padding-left: auto; }
  .sm\:pxauto {
    padding-left: auto;
    padding-right: auto; }
  .sm\:pyauto {
    padding-top: auto;
    padding-bottom: auto; }
  .sm\:col-0 {
    width: 0%; }
  .sm\:off-0 {
    margin-left: 0%; }
  .sm\:col-1 {
    width: 8.33333%; }
  .sm\:off-1 {
    margin-left: 8.33333%; }
  .sm\:col-2 {
    width: 16.66667%; }
  .sm\:off-2 {
    margin-left: 16.66667%; }
  .sm\:col-3 {
    width: 25%; }
  .sm\:off-3 {
    margin-left: 25%; }
  .sm\:col-4 {
    width: 33.33333%; }
  .sm\:off-4 {
    margin-left: 33.33333%; }
  .sm\:col-5 {
    width: 41.66667%; }
  .sm\:off-5 {
    margin-left: 41.66667%; }
  .sm\:col-6 {
    width: 50%; }
  .sm\:off-6 {
    margin-left: 50%; }
  .sm\:col-7 {
    width: 58.33333%; }
  .sm\:off-7 {
    margin-left: 58.33333%; }
  .sm\:col-8 {
    width: 66.66667%; }
  .sm\:off-8 {
    margin-left: 66.66667%; }
  .sm\:col-9 {
    width: 75%; }
  .sm\:off-9 {
    margin-left: 75%; }
  .sm\:col-10 {
    width: 83.33333%; }
  .sm\:off-10 {
    margin-left: 83.33333%; }
  .sm\:col-11 {
    width: 91.66667%; }
  .sm\:off-11 {
    margin-left: 91.66667%; }
  .sm\:col-12 {
    width: 100%; }
  .sm\:off-12 {
    margin-left: 100%; } }

@media (min-width: 832px) {
  .md\:wauto {
    width: auto; }
  .md\:hauto {
    height: auto; }
  .md\:text-left {
    text-align: left; }
  .md\:text-right {
    text-align: right; }
  .md\:text-center {
    text-align: center; }
  .md\:text-justify {
    text-align: justify; }
  .md\:inline {
    display: inline; }
  .md\:block {
    display: block; }
  .md\:inline-block {
    display: inline-block; }
  .md\:table {
    display: table; }
  .md\:table-cell {
    display: table-cell; }
  .md\:flex {
    display: flex; }
  .md\:inline-flex {
    display: inline-flex; }
  .md\:vertical-align-top {
    vertical-align: top; }
  .md\:vertical-align-middle {
    vertical-align: middle; }
  .md\:vertical-align-bottom {
    vertical-align: bottom; }
  .md\:absolute {
    position: absolute; }
  .md\:relative {
    position: relative; }
  .md\:fixed {
    position: fixed; }
  .md\:sticky {
    position: sticky; }
  .md\:left {
    float: left; }
  .md\:right {
    float: right; }
  .md\:flex-row {
    flex-direction: row; }
  .md\:flex-col {
    flex-direction: column; }
  .md\:flex-row-reverse {
    flex-direction: row-reverse; }
  .md\:flex-col-reverse {
    flex-direction: column-reverse; }
  .md\:flex-wrap {
    flex-wrap: wrap; }
  .md\:flex-nowrap {
    flex-wrap: nowrap; }
  .md\:items-start {
    align-items: flex-start; }
  .md\:items-end {
    align-items: flex-end; }
  .md\:items-center {
    align-items: center; }
  .md\:items-baseline {
    align-items: baseline; }
  .md\:items-stretch {
    align-items: stretch; }
  .md\:justify-start {
    justify-content: flex-start; }
  .md\:justify-end {
    justify-content: flex-end; }
  .md\:justify-center {
    justify-content: center; }
  .md\:justify-between {
    justify-content: space-between; }
  .md\:justify-around {
    justify-content: space-around; }
  .md\:justify-evenly {
    justify-content: space-evenly; }
  .md\:justify-stretch {
    justify-content: stretch; }
  .md\:flex-1 {
    flex: 1; }
  .md\:flex-2 {
    flex: 2; }
  .md\:flex-3 {
    flex: 3; }
  .md\:flex-none {
    flex: none; }
  .md\:order-0 {
    order: 0; }
  .md\:order-1 {
    order: 1; }
  .md\:order-2 {
    order: 2; }
  .md\:order-3 {
    order: 3; }
  .md\:order-first {
    order: -1; }
  .md\:order-last {
    order: 9999; }
  .md\:none {
    display: none; }
  .md\:m0 {
    margin: 0rem; }
  .md\:mt0 {
    margin-top: 0rem; }
  .md\:mr0 {
    margin-right: 0rem; }
  .md\:mb0 {
    margin-bottom: 0rem; }
  .md\:ml0 {
    margin-left: 0rem; }
  .md\:mx0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .md\:my0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .md\:mxn0 {
    margin-left: -0rem;
    margin-right: -0rem; }
  .md\:p0 {
    padding: 0rem; }
  .md\:pt0 {
    padding-top: 0rem; }
  .md\:pr0 {
    padding-right: 0rem; }
  .md\:pb0 {
    padding-bottom: 0rem; }
  .md\:pl0 {
    padding-left: 0rem; }
  .md\:px0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .md\:py0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .md\:m_25 {
    margin: 0.25rem; }
  .md\:mt_25 {
    margin-top: 0.25rem; }
  .md\:mr_25 {
    margin-right: 0.25rem; }
  .md\:mb_25 {
    margin-bottom: 0.25rem; }
  .md\:ml_25 {
    margin-left: 0.25rem; }
  .md\:mx_25 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .md\:my_25 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .md\:mxn_25 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .md\:p_25 {
    padding: 0.25rem; }
  .md\:pt_25 {
    padding-top: 0.25rem; }
  .md\:pr_25 {
    padding-right: 0.25rem; }
  .md\:pb_25 {
    padding-bottom: 0.25rem; }
  .md\:pl_25 {
    padding-left: 0.25rem; }
  .md\:px_25 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .md\:py_25 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .md\:m_5 {
    margin: 0.5rem; }
  .md\:mt_5 {
    margin-top: 0.5rem; }
  .md\:mr_5 {
    margin-right: 0.5rem; }
  .md\:mb_5 {
    margin-bottom: 0.5rem; }
  .md\:ml_5 {
    margin-left: 0.5rem; }
  .md\:mx_5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .md\:my_5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .md\:mxn_5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .md\:p_5 {
    padding: 0.5rem; }
  .md\:pt_5 {
    padding-top: 0.5rem; }
  .md\:pr_5 {
    padding-right: 0.5rem; }
  .md\:pb_5 {
    padding-bottom: 0.5rem; }
  .md\:pl_5 {
    padding-left: 0.5rem; }
  .md\:px_5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .md\:py_5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .md\:m_75 {
    margin: 0.75rem; }
  .md\:mt_75 {
    margin-top: 0.75rem; }
  .md\:mr_75 {
    margin-right: 0.75rem; }
  .md\:mb_75 {
    margin-bottom: 0.75rem; }
  .md\:ml_75 {
    margin-left: 0.75rem; }
  .md\:mx_75 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .md\:my_75 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .md\:mxn_75 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .md\:p_75 {
    padding: 0.75rem; }
  .md\:pt_75 {
    padding-top: 0.75rem; }
  .md\:pr_75 {
    padding-right: 0.75rem; }
  .md\:pb_75 {
    padding-bottom: 0.75rem; }
  .md\:pl_75 {
    padding-left: 0.75rem; }
  .md\:px_75 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .md\:py_75 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .md\:m1 {
    margin: 1rem; }
  .md\:mt1 {
    margin-top: 1rem; }
  .md\:mr1 {
    margin-right: 1rem; }
  .md\:mb1 {
    margin-bottom: 1rem; }
  .md\:ml1 {
    margin-left: 1rem; }
  .md\:mx1 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .md\:my1 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .md\:mxn1 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .md\:p1 {
    padding: 1rem; }
  .md\:pt1 {
    padding-top: 1rem; }
  .md\:pr1 {
    padding-right: 1rem; }
  .md\:pb1 {
    padding-bottom: 1rem; }
  .md\:pl1 {
    padding-left: 1rem; }
  .md\:px1 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .md\:py1 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .md\:m1_5 {
    margin: 1.5rem; }
  .md\:mt1_5 {
    margin-top: 1.5rem; }
  .md\:mr1_5 {
    margin-right: 1.5rem; }
  .md\:mb1_5 {
    margin-bottom: 1.5rem; }
  .md\:ml1_5 {
    margin-left: 1.5rem; }
  .md\:mx1_5 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .md\:my1_5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .md\:mxn1_5 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .md\:p1_5 {
    padding: 1.5rem; }
  .md\:pt1_5 {
    padding-top: 1.5rem; }
  .md\:pr1_5 {
    padding-right: 1.5rem; }
  .md\:pb1_5 {
    padding-bottom: 1.5rem; }
  .md\:pl1_5 {
    padding-left: 1.5rem; }
  .md\:px1_5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .md\:py1_5 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .md\:m1_75 {
    margin: 1.75rem; }
  .md\:mt1_75 {
    margin-top: 1.75rem; }
  .md\:mr1_75 {
    margin-right: 1.75rem; }
  .md\:mb1_75 {
    margin-bottom: 1.75rem; }
  .md\:ml1_75 {
    margin-left: 1.75rem; }
  .md\:mx1_75 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .md\:my1_75 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .md\:mxn1_75 {
    margin-left: -1.75rem;
    margin-right: -1.75rem; }
  .md\:p1_75 {
    padding: 1.75rem; }
  .md\:pt1_75 {
    padding-top: 1.75rem; }
  .md\:pr1_75 {
    padding-right: 1.75rem; }
  .md\:pb1_75 {
    padding-bottom: 1.75rem; }
  .md\:pl1_75 {
    padding-left: 1.75rem; }
  .md\:px1_75 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .md\:py1_75 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .md\:m2 {
    margin: 2rem; }
  .md\:mt2 {
    margin-top: 2rem; }
  .md\:mr2 {
    margin-right: 2rem; }
  .md\:mb2 {
    margin-bottom: 2rem; }
  .md\:ml2 {
    margin-left: 2rem; }
  .md\:mx2 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .md\:my2 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .md\:mxn2 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .md\:p2 {
    padding: 2rem; }
  .md\:pt2 {
    padding-top: 2rem; }
  .md\:pr2 {
    padding-right: 2rem; }
  .md\:pb2 {
    padding-bottom: 2rem; }
  .md\:pl2 {
    padding-left: 2rem; }
  .md\:px2 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .md\:py2 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .md\:m2_5 {
    margin: 2.5rem; }
  .md\:mt2_5 {
    margin-top: 2.5rem; }
  .md\:mr2_5 {
    margin-right: 2.5rem; }
  .md\:mb2_5 {
    margin-bottom: 2.5rem; }
  .md\:ml2_5 {
    margin-left: 2.5rem; }
  .md\:mx2_5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .md\:my2_5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .md\:mxn2_5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .md\:p2_5 {
    padding: 2.5rem; }
  .md\:pt2_5 {
    padding-top: 2.5rem; }
  .md\:pr2_5 {
    padding-right: 2.5rem; }
  .md\:pb2_5 {
    padding-bottom: 2.5rem; }
  .md\:pl2_5 {
    padding-left: 2.5rem; }
  .md\:px2_5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .md\:py2_5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .md\:m3 {
    margin: 3rem; }
  .md\:mt3 {
    margin-top: 3rem; }
  .md\:mr3 {
    margin-right: 3rem; }
  .md\:mb3 {
    margin-bottom: 3rem; }
  .md\:ml3 {
    margin-left: 3rem; }
  .md\:mx3 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .md\:my3 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .md\:mxn3 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .md\:p3 {
    padding: 3rem; }
  .md\:pt3 {
    padding-top: 3rem; }
  .md\:pr3 {
    padding-right: 3rem; }
  .md\:pb3 {
    padding-bottom: 3rem; }
  .md\:pl3 {
    padding-left: 3rem; }
  .md\:px3 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .md\:py3 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .md\:m4 {
    margin: 4rem; }
  .md\:mt4 {
    margin-top: 4rem; }
  .md\:mr4 {
    margin-right: 4rem; }
  .md\:mb4 {
    margin-bottom: 4rem; }
  .md\:ml4 {
    margin-left: 4rem; }
  .md\:mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .md\:my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .md\:mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .md\:p4 {
    padding: 4rem; }
  .md\:pt4 {
    padding-top: 4rem; }
  .md\:pr4 {
    padding-right: 4rem; }
  .md\:pb4 {
    padding-bottom: 4rem; }
  .md\:pl4 {
    padding-left: 4rem; }
  .md\:px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .md\:py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .md\:m6 {
    margin: 6rem; }
  .md\:mt6 {
    margin-top: 6rem; }
  .md\:mr6 {
    margin-right: 6rem; }
  .md\:mb6 {
    margin-bottom: 6rem; }
  .md\:ml6 {
    margin-left: 6rem; }
  .md\:mx6 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .md\:my6 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .md\:mxn6 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .md\:p6 {
    padding: 6rem; }
  .md\:pt6 {
    padding-top: 6rem; }
  .md\:pr6 {
    padding-right: 6rem; }
  .md\:pb6 {
    padding-bottom: 6rem; }
  .md\:pl6 {
    padding-left: 6rem; }
  .md\:px6 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .md\:py6 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .md\:m8 {
    margin: 8rem; }
  .md\:mt8 {
    margin-top: 8rem; }
  .md\:mr8 {
    margin-right: 8rem; }
  .md\:mb8 {
    margin-bottom: 8rem; }
  .md\:ml8 {
    margin-left: 8rem; }
  .md\:mx8 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .md\:my8 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .md\:mxn8 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .md\:p8 {
    padding: 8rem; }
  .md\:pt8 {
    padding-top: 8rem; }
  .md\:pr8 {
    padding-right: 8rem; }
  .md\:pb8 {
    padding-bottom: 8rem; }
  .md\:pl8 {
    padding-left: 8rem; }
  .md\:px8 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .md\:py8 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .md\:mauto {
    margin: auto; }
  .md\:mtauto {
    margin-top: auto; }
  .md\:mrauto {
    margin-right: auto; }
  .md\:mbauto {
    margin-bottom: auto; }
  .md\:mlauto {
    margin-left: auto; }
  .md\:mxauto {
    margin-left: auto;
    margin-right: auto; }
  .md\:myauto {
    margin-top: auto;
    margin-bottom: auto; }
  .md\:mxnauto {
    margin-left: -auto;
    margin-right: -auto; }
  .md\:pauto {
    padding: auto; }
  .md\:ptauto {
    padding-top: auto; }
  .md\:prauto {
    padding-right: auto; }
  .md\:pbauto {
    padding-bottom: auto; }
  .md\:plauto {
    padding-left: auto; }
  .md\:pxauto {
    padding-left: auto;
    padding-right: auto; }
  .md\:pyauto {
    padding-top: auto;
    padding-bottom: auto; }
  .md\:col-0 {
    width: 0%; }
  .md\:off-0 {
    margin-left: 0%; }
  .md\:col-1 {
    width: 8.33333%; }
  .md\:off-1 {
    margin-left: 8.33333%; }
  .md\:col-2 {
    width: 16.66667%; }
  .md\:off-2 {
    margin-left: 16.66667%; }
  .md\:col-3 {
    width: 25%; }
  .md\:off-3 {
    margin-left: 25%; }
  .md\:col-4 {
    width: 33.33333%; }
  .md\:off-4 {
    margin-left: 33.33333%; }
  .md\:col-5 {
    width: 41.66667%; }
  .md\:off-5 {
    margin-left: 41.66667%; }
  .md\:col-6 {
    width: 50%; }
  .md\:off-6 {
    margin-left: 50%; }
  .md\:col-7 {
    width: 58.33333%; }
  .md\:off-7 {
    margin-left: 58.33333%; }
  .md\:col-8 {
    width: 66.66667%; }
  .md\:off-8 {
    margin-left: 66.66667%; }
  .md\:col-9 {
    width: 75%; }
  .md\:off-9 {
    margin-left: 75%; }
  .md\:col-10 {
    width: 83.33333%; }
  .md\:off-10 {
    margin-left: 83.33333%; }
  .md\:col-11 {
    width: 91.66667%; }
  .md\:off-11 {
    margin-left: 91.66667%; }
  .md\:col-12 {
    width: 100%; }
  .md\:off-12 {
    margin-left: 100%; } }

@media (min-width: 1024px) {
  .lg\:wauto {
    width: auto; }
  .lg\:hauto {
    height: auto; }
  .lg\:text-left {
    text-align: left; }
  .lg\:text-right {
    text-align: right; }
  .lg\:text-center {
    text-align: center; }
  .lg\:text-justify {
    text-align: justify; }
  .lg\:inline {
    display: inline; }
  .lg\:block {
    display: block; }
  .lg\:inline-block {
    display: inline-block; }
  .lg\:table {
    display: table; }
  .lg\:table-cell {
    display: table-cell; }
  .lg\:flex {
    display: flex; }
  .lg\:inline-flex {
    display: inline-flex; }
  .lg\:vertical-align-top {
    vertical-align: top; }
  .lg\:vertical-align-middle {
    vertical-align: middle; }
  .lg\:vertical-align-bottom {
    vertical-align: bottom; }
  .lg\:absolute {
    position: absolute; }
  .lg\:relative {
    position: relative; }
  .lg\:fixed {
    position: fixed; }
  .lg\:sticky {
    position: sticky; }
  .lg\:left {
    float: left; }
  .lg\:right {
    float: right; }
  .lg\:flex-row {
    flex-direction: row; }
  .lg\:flex-col {
    flex-direction: column; }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse; }
  .lg\:flex-col-reverse {
    flex-direction: column-reverse; }
  .lg\:flex-wrap {
    flex-wrap: wrap; }
  .lg\:flex-nowrap {
    flex-wrap: nowrap; }
  .lg\:items-start {
    align-items: flex-start; }
  .lg\:items-end {
    align-items: flex-end; }
  .lg\:items-center {
    align-items: center; }
  .lg\:items-baseline {
    align-items: baseline; }
  .lg\:items-stretch {
    align-items: stretch; }
  .lg\:justify-start {
    justify-content: flex-start; }
  .lg\:justify-end {
    justify-content: flex-end; }
  .lg\:justify-center {
    justify-content: center; }
  .lg\:justify-between {
    justify-content: space-between; }
  .lg\:justify-around {
    justify-content: space-around; }
  .lg\:justify-evenly {
    justify-content: space-evenly; }
  .lg\:justify-stretch {
    justify-content: stretch; }
  .lg\:flex-1 {
    flex: 1; }
  .lg\:flex-2 {
    flex: 2; }
  .lg\:flex-3 {
    flex: 3; }
  .lg\:flex-none {
    flex: none; }
  .lg\:order-0 {
    order: 0; }
  .lg\:order-1 {
    order: 1; }
  .lg\:order-2 {
    order: 2; }
  .lg\:order-3 {
    order: 3; }
  .lg\:order-first {
    order: -1; }
  .lg\:order-last {
    order: 9999; }
  .lg\:none {
    display: none; }
  .lg\:m0 {
    margin: 0rem; }
  .lg\:mt0 {
    margin-top: 0rem; }
  .lg\:mr0 {
    margin-right: 0rem; }
  .lg\:mb0 {
    margin-bottom: 0rem; }
  .lg\:ml0 {
    margin-left: 0rem; }
  .lg\:mx0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .lg\:my0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .lg\:mxn0 {
    margin-left: -0rem;
    margin-right: -0rem; }
  .lg\:p0 {
    padding: 0rem; }
  .lg\:pt0 {
    padding-top: 0rem; }
  .lg\:pr0 {
    padding-right: 0rem; }
  .lg\:pb0 {
    padding-bottom: 0rem; }
  .lg\:pl0 {
    padding-left: 0rem; }
  .lg\:px0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .lg\:py0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .lg\:m_25 {
    margin: 0.25rem; }
  .lg\:mt_25 {
    margin-top: 0.25rem; }
  .lg\:mr_25 {
    margin-right: 0.25rem; }
  .lg\:mb_25 {
    margin-bottom: 0.25rem; }
  .lg\:ml_25 {
    margin-left: 0.25rem; }
  .lg\:mx_25 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .lg\:my_25 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .lg\:mxn_25 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .lg\:p_25 {
    padding: 0.25rem; }
  .lg\:pt_25 {
    padding-top: 0.25rem; }
  .lg\:pr_25 {
    padding-right: 0.25rem; }
  .lg\:pb_25 {
    padding-bottom: 0.25rem; }
  .lg\:pl_25 {
    padding-left: 0.25rem; }
  .lg\:px_25 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .lg\:py_25 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .lg\:m_5 {
    margin: 0.5rem; }
  .lg\:mt_5 {
    margin-top: 0.5rem; }
  .lg\:mr_5 {
    margin-right: 0.5rem; }
  .lg\:mb_5 {
    margin-bottom: 0.5rem; }
  .lg\:ml_5 {
    margin-left: 0.5rem; }
  .lg\:mx_5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .lg\:my_5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .lg\:mxn_5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .lg\:p_5 {
    padding: 0.5rem; }
  .lg\:pt_5 {
    padding-top: 0.5rem; }
  .lg\:pr_5 {
    padding-right: 0.5rem; }
  .lg\:pb_5 {
    padding-bottom: 0.5rem; }
  .lg\:pl_5 {
    padding-left: 0.5rem; }
  .lg\:px_5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .lg\:py_5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .lg\:m_75 {
    margin: 0.75rem; }
  .lg\:mt_75 {
    margin-top: 0.75rem; }
  .lg\:mr_75 {
    margin-right: 0.75rem; }
  .lg\:mb_75 {
    margin-bottom: 0.75rem; }
  .lg\:ml_75 {
    margin-left: 0.75rem; }
  .lg\:mx_75 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .lg\:my_75 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .lg\:mxn_75 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .lg\:p_75 {
    padding: 0.75rem; }
  .lg\:pt_75 {
    padding-top: 0.75rem; }
  .lg\:pr_75 {
    padding-right: 0.75rem; }
  .lg\:pb_75 {
    padding-bottom: 0.75rem; }
  .lg\:pl_75 {
    padding-left: 0.75rem; }
  .lg\:px_75 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .lg\:py_75 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .lg\:m1 {
    margin: 1rem; }
  .lg\:mt1 {
    margin-top: 1rem; }
  .lg\:mr1 {
    margin-right: 1rem; }
  .lg\:mb1 {
    margin-bottom: 1rem; }
  .lg\:ml1 {
    margin-left: 1rem; }
  .lg\:mx1 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .lg\:my1 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .lg\:mxn1 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .lg\:p1 {
    padding: 1rem; }
  .lg\:pt1 {
    padding-top: 1rem; }
  .lg\:pr1 {
    padding-right: 1rem; }
  .lg\:pb1 {
    padding-bottom: 1rem; }
  .lg\:pl1 {
    padding-left: 1rem; }
  .lg\:px1 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .lg\:py1 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .lg\:m1_5 {
    margin: 1.5rem; }
  .lg\:mt1_5 {
    margin-top: 1.5rem; }
  .lg\:mr1_5 {
    margin-right: 1.5rem; }
  .lg\:mb1_5 {
    margin-bottom: 1.5rem; }
  .lg\:ml1_5 {
    margin-left: 1.5rem; }
  .lg\:mx1_5 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .lg\:my1_5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .lg\:mxn1_5 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .lg\:p1_5 {
    padding: 1.5rem; }
  .lg\:pt1_5 {
    padding-top: 1.5rem; }
  .lg\:pr1_5 {
    padding-right: 1.5rem; }
  .lg\:pb1_5 {
    padding-bottom: 1.5rem; }
  .lg\:pl1_5 {
    padding-left: 1.5rem; }
  .lg\:px1_5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .lg\:py1_5 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .lg\:m1_75 {
    margin: 1.75rem; }
  .lg\:mt1_75 {
    margin-top: 1.75rem; }
  .lg\:mr1_75 {
    margin-right: 1.75rem; }
  .lg\:mb1_75 {
    margin-bottom: 1.75rem; }
  .lg\:ml1_75 {
    margin-left: 1.75rem; }
  .lg\:mx1_75 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .lg\:my1_75 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .lg\:mxn1_75 {
    margin-left: -1.75rem;
    margin-right: -1.75rem; }
  .lg\:p1_75 {
    padding: 1.75rem; }
  .lg\:pt1_75 {
    padding-top: 1.75rem; }
  .lg\:pr1_75 {
    padding-right: 1.75rem; }
  .lg\:pb1_75 {
    padding-bottom: 1.75rem; }
  .lg\:pl1_75 {
    padding-left: 1.75rem; }
  .lg\:px1_75 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .lg\:py1_75 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .lg\:m2 {
    margin: 2rem; }
  .lg\:mt2 {
    margin-top: 2rem; }
  .lg\:mr2 {
    margin-right: 2rem; }
  .lg\:mb2 {
    margin-bottom: 2rem; }
  .lg\:ml2 {
    margin-left: 2rem; }
  .lg\:mx2 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .lg\:my2 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .lg\:mxn2 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .lg\:p2 {
    padding: 2rem; }
  .lg\:pt2 {
    padding-top: 2rem; }
  .lg\:pr2 {
    padding-right: 2rem; }
  .lg\:pb2 {
    padding-bottom: 2rem; }
  .lg\:pl2 {
    padding-left: 2rem; }
  .lg\:px2 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .lg\:py2 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .lg\:m2_5 {
    margin: 2.5rem; }
  .lg\:mt2_5 {
    margin-top: 2.5rem; }
  .lg\:mr2_5 {
    margin-right: 2.5rem; }
  .lg\:mb2_5 {
    margin-bottom: 2.5rem; }
  .lg\:ml2_5 {
    margin-left: 2.5rem; }
  .lg\:mx2_5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .lg\:my2_5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .lg\:mxn2_5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .lg\:p2_5 {
    padding: 2.5rem; }
  .lg\:pt2_5 {
    padding-top: 2.5rem; }
  .lg\:pr2_5 {
    padding-right: 2.5rem; }
  .lg\:pb2_5 {
    padding-bottom: 2.5rem; }
  .lg\:pl2_5 {
    padding-left: 2.5rem; }
  .lg\:px2_5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .lg\:py2_5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .lg\:m3 {
    margin: 3rem; }
  .lg\:mt3 {
    margin-top: 3rem; }
  .lg\:mr3 {
    margin-right: 3rem; }
  .lg\:mb3 {
    margin-bottom: 3rem; }
  .lg\:ml3 {
    margin-left: 3rem; }
  .lg\:mx3 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .lg\:my3 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .lg\:mxn3 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .lg\:p3 {
    padding: 3rem; }
  .lg\:pt3 {
    padding-top: 3rem; }
  .lg\:pr3 {
    padding-right: 3rem; }
  .lg\:pb3 {
    padding-bottom: 3rem; }
  .lg\:pl3 {
    padding-left: 3rem; }
  .lg\:px3 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .lg\:py3 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .lg\:m4 {
    margin: 4rem; }
  .lg\:mt4 {
    margin-top: 4rem; }
  .lg\:mr4 {
    margin-right: 4rem; }
  .lg\:mb4 {
    margin-bottom: 4rem; }
  .lg\:ml4 {
    margin-left: 4rem; }
  .lg\:mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .lg\:my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .lg\:mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .lg\:p4 {
    padding: 4rem; }
  .lg\:pt4 {
    padding-top: 4rem; }
  .lg\:pr4 {
    padding-right: 4rem; }
  .lg\:pb4 {
    padding-bottom: 4rem; }
  .lg\:pl4 {
    padding-left: 4rem; }
  .lg\:px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .lg\:py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .lg\:m6 {
    margin: 6rem; }
  .lg\:mt6 {
    margin-top: 6rem; }
  .lg\:mr6 {
    margin-right: 6rem; }
  .lg\:mb6 {
    margin-bottom: 6rem; }
  .lg\:ml6 {
    margin-left: 6rem; }
  .lg\:mx6 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .lg\:my6 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .lg\:mxn6 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .lg\:p6 {
    padding: 6rem; }
  .lg\:pt6 {
    padding-top: 6rem; }
  .lg\:pr6 {
    padding-right: 6rem; }
  .lg\:pb6 {
    padding-bottom: 6rem; }
  .lg\:pl6 {
    padding-left: 6rem; }
  .lg\:px6 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .lg\:py6 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .lg\:m8 {
    margin: 8rem; }
  .lg\:mt8 {
    margin-top: 8rem; }
  .lg\:mr8 {
    margin-right: 8rem; }
  .lg\:mb8 {
    margin-bottom: 8rem; }
  .lg\:ml8 {
    margin-left: 8rem; }
  .lg\:mx8 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .lg\:my8 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .lg\:mxn8 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .lg\:p8 {
    padding: 8rem; }
  .lg\:pt8 {
    padding-top: 8rem; }
  .lg\:pr8 {
    padding-right: 8rem; }
  .lg\:pb8 {
    padding-bottom: 8rem; }
  .lg\:pl8 {
    padding-left: 8rem; }
  .lg\:px8 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .lg\:py8 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .lg\:mauto {
    margin: auto; }
  .lg\:mtauto {
    margin-top: auto; }
  .lg\:mrauto {
    margin-right: auto; }
  .lg\:mbauto {
    margin-bottom: auto; }
  .lg\:mlauto {
    margin-left: auto; }
  .lg\:mxauto {
    margin-left: auto;
    margin-right: auto; }
  .lg\:myauto {
    margin-top: auto;
    margin-bottom: auto; }
  .lg\:mxnauto {
    margin-left: -auto;
    margin-right: -auto; }
  .lg\:pauto {
    padding: auto; }
  .lg\:ptauto {
    padding-top: auto; }
  .lg\:prauto {
    padding-right: auto; }
  .lg\:pbauto {
    padding-bottom: auto; }
  .lg\:plauto {
    padding-left: auto; }
  .lg\:pxauto {
    padding-left: auto;
    padding-right: auto; }
  .lg\:pyauto {
    padding-top: auto;
    padding-bottom: auto; }
  .lg\:col-0 {
    width: 0%; }
  .lg\:off-0 {
    margin-left: 0%; }
  .lg\:col-1 {
    width: 8.33333%; }
  .lg\:off-1 {
    margin-left: 8.33333%; }
  .lg\:col-2 {
    width: 16.66667%; }
  .lg\:off-2 {
    margin-left: 16.66667%; }
  .lg\:col-3 {
    width: 25%; }
  .lg\:off-3 {
    margin-left: 25%; }
  .lg\:col-4 {
    width: 33.33333%; }
  .lg\:off-4 {
    margin-left: 33.33333%; }
  .lg\:col-5 {
    width: 41.66667%; }
  .lg\:off-5 {
    margin-left: 41.66667%; }
  .lg\:col-6 {
    width: 50%; }
  .lg\:off-6 {
    margin-left: 50%; }
  .lg\:col-7 {
    width: 58.33333%; }
  .lg\:off-7 {
    margin-left: 58.33333%; }
  .lg\:col-8 {
    width: 66.66667%; }
  .lg\:off-8 {
    margin-left: 66.66667%; }
  .lg\:col-9 {
    width: 75%; }
  .lg\:off-9 {
    margin-left: 75%; }
  .lg\:col-10 {
    width: 83.33333%; }
  .lg\:off-10 {
    margin-left: 83.33333%; }
  .lg\:col-11 {
    width: 91.66667%; }
  .lg\:off-11 {
    margin-left: 91.66667%; }
  .lg\:col-12 {
    width: 100%; }
  .lg\:off-12 {
    margin-left: 100%; } }

@media (min-width: 1152px) {
  .xl\:wauto {
    width: auto; }
  .xl\:hauto {
    height: auto; }
  .xl\:text-left {
    text-align: left; }
  .xl\:text-right {
    text-align: right; }
  .xl\:text-center {
    text-align: center; }
  .xl\:text-justify {
    text-align: justify; }
  .xl\:inline {
    display: inline; }
  .xl\:block {
    display: block; }
  .xl\:inline-block {
    display: inline-block; }
  .xl\:table {
    display: table; }
  .xl\:table-cell {
    display: table-cell; }
  .xl\:flex {
    display: flex; }
  .xl\:inline-flex {
    display: inline-flex; }
  .xl\:vertical-align-top {
    vertical-align: top; }
  .xl\:vertical-align-middle {
    vertical-align: middle; }
  .xl\:vertical-align-bottom {
    vertical-align: bottom; }
  .xl\:absolute {
    position: absolute; }
  .xl\:relative {
    position: relative; }
  .xl\:fixed {
    position: fixed; }
  .xl\:sticky {
    position: sticky; }
  .xl\:left {
    float: left; }
  .xl\:right {
    float: right; }
  .xl\:flex-row {
    flex-direction: row; }
  .xl\:flex-col {
    flex-direction: column; }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse; }
  .xl\:flex-col-reverse {
    flex-direction: column-reverse; }
  .xl\:flex-wrap {
    flex-wrap: wrap; }
  .xl\:flex-nowrap {
    flex-wrap: nowrap; }
  .xl\:items-start {
    align-items: flex-start; }
  .xl\:items-end {
    align-items: flex-end; }
  .xl\:items-center {
    align-items: center; }
  .xl\:items-baseline {
    align-items: baseline; }
  .xl\:items-stretch {
    align-items: stretch; }
  .xl\:justify-start {
    justify-content: flex-start; }
  .xl\:justify-end {
    justify-content: flex-end; }
  .xl\:justify-center {
    justify-content: center; }
  .xl\:justify-between {
    justify-content: space-between; }
  .xl\:justify-around {
    justify-content: space-around; }
  .xl\:justify-evenly {
    justify-content: space-evenly; }
  .xl\:justify-stretch {
    justify-content: stretch; }
  .xl\:flex-1 {
    flex: 1; }
  .xl\:flex-2 {
    flex: 2; }
  .xl\:flex-3 {
    flex: 3; }
  .xl\:flex-none {
    flex: none; }
  .xl\:order-0 {
    order: 0; }
  .xl\:order-1 {
    order: 1; }
  .xl\:order-2 {
    order: 2; }
  .xl\:order-3 {
    order: 3; }
  .xl\:order-first {
    order: -1; }
  .xl\:order-last {
    order: 9999; }
  .xl\:none {
    display: none; }
  .xl\:m0 {
    margin: 0rem; }
  .xl\:mt0 {
    margin-top: 0rem; }
  .xl\:mr0 {
    margin-right: 0rem; }
  .xl\:mb0 {
    margin-bottom: 0rem; }
  .xl\:ml0 {
    margin-left: 0rem; }
  .xl\:mx0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .xl\:my0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .xl\:mxn0 {
    margin-left: -0rem;
    margin-right: -0rem; }
  .xl\:p0 {
    padding: 0rem; }
  .xl\:pt0 {
    padding-top: 0rem; }
  .xl\:pr0 {
    padding-right: 0rem; }
  .xl\:pb0 {
    padding-bottom: 0rem; }
  .xl\:pl0 {
    padding-left: 0rem; }
  .xl\:px0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .xl\:py0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .xl\:m_25 {
    margin: 0.25rem; }
  .xl\:mt_25 {
    margin-top: 0.25rem; }
  .xl\:mr_25 {
    margin-right: 0.25rem; }
  .xl\:mb_25 {
    margin-bottom: 0.25rem; }
  .xl\:ml_25 {
    margin-left: 0.25rem; }
  .xl\:mx_25 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .xl\:my_25 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .xl\:mxn_25 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .xl\:p_25 {
    padding: 0.25rem; }
  .xl\:pt_25 {
    padding-top: 0.25rem; }
  .xl\:pr_25 {
    padding-right: 0.25rem; }
  .xl\:pb_25 {
    padding-bottom: 0.25rem; }
  .xl\:pl_25 {
    padding-left: 0.25rem; }
  .xl\:px_25 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .xl\:py_25 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .xl\:m_5 {
    margin: 0.5rem; }
  .xl\:mt_5 {
    margin-top: 0.5rem; }
  .xl\:mr_5 {
    margin-right: 0.5rem; }
  .xl\:mb_5 {
    margin-bottom: 0.5rem; }
  .xl\:ml_5 {
    margin-left: 0.5rem; }
  .xl\:mx_5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .xl\:my_5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .xl\:mxn_5 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .xl\:p_5 {
    padding: 0.5rem; }
  .xl\:pt_5 {
    padding-top: 0.5rem; }
  .xl\:pr_5 {
    padding-right: 0.5rem; }
  .xl\:pb_5 {
    padding-bottom: 0.5rem; }
  .xl\:pl_5 {
    padding-left: 0.5rem; }
  .xl\:px_5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .xl\:py_5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .xl\:m_75 {
    margin: 0.75rem; }
  .xl\:mt_75 {
    margin-top: 0.75rem; }
  .xl\:mr_75 {
    margin-right: 0.75rem; }
  .xl\:mb_75 {
    margin-bottom: 0.75rem; }
  .xl\:ml_75 {
    margin-left: 0.75rem; }
  .xl\:mx_75 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .xl\:my_75 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .xl\:mxn_75 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .xl\:p_75 {
    padding: 0.75rem; }
  .xl\:pt_75 {
    padding-top: 0.75rem; }
  .xl\:pr_75 {
    padding-right: 0.75rem; }
  .xl\:pb_75 {
    padding-bottom: 0.75rem; }
  .xl\:pl_75 {
    padding-left: 0.75rem; }
  .xl\:px_75 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .xl\:py_75 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .xl\:m1 {
    margin: 1rem; }
  .xl\:mt1 {
    margin-top: 1rem; }
  .xl\:mr1 {
    margin-right: 1rem; }
  .xl\:mb1 {
    margin-bottom: 1rem; }
  .xl\:ml1 {
    margin-left: 1rem; }
  .xl\:mx1 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .xl\:my1 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .xl\:mxn1 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .xl\:p1 {
    padding: 1rem; }
  .xl\:pt1 {
    padding-top: 1rem; }
  .xl\:pr1 {
    padding-right: 1rem; }
  .xl\:pb1 {
    padding-bottom: 1rem; }
  .xl\:pl1 {
    padding-left: 1rem; }
  .xl\:px1 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .xl\:py1 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .xl\:m1_5 {
    margin: 1.5rem; }
  .xl\:mt1_5 {
    margin-top: 1.5rem; }
  .xl\:mr1_5 {
    margin-right: 1.5rem; }
  .xl\:mb1_5 {
    margin-bottom: 1.5rem; }
  .xl\:ml1_5 {
    margin-left: 1.5rem; }
  .xl\:mx1_5 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .xl\:my1_5 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .xl\:mxn1_5 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .xl\:p1_5 {
    padding: 1.5rem; }
  .xl\:pt1_5 {
    padding-top: 1.5rem; }
  .xl\:pr1_5 {
    padding-right: 1.5rem; }
  .xl\:pb1_5 {
    padding-bottom: 1.5rem; }
  .xl\:pl1_5 {
    padding-left: 1.5rem; }
  .xl\:px1_5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .xl\:py1_5 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .xl\:m1_75 {
    margin: 1.75rem; }
  .xl\:mt1_75 {
    margin-top: 1.75rem; }
  .xl\:mr1_75 {
    margin-right: 1.75rem; }
  .xl\:mb1_75 {
    margin-bottom: 1.75rem; }
  .xl\:ml1_75 {
    margin-left: 1.75rem; }
  .xl\:mx1_75 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .xl\:my1_75 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .xl\:mxn1_75 {
    margin-left: -1.75rem;
    margin-right: -1.75rem; }
  .xl\:p1_75 {
    padding: 1.75rem; }
  .xl\:pt1_75 {
    padding-top: 1.75rem; }
  .xl\:pr1_75 {
    padding-right: 1.75rem; }
  .xl\:pb1_75 {
    padding-bottom: 1.75rem; }
  .xl\:pl1_75 {
    padding-left: 1.75rem; }
  .xl\:px1_75 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .xl\:py1_75 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .xl\:m2 {
    margin: 2rem; }
  .xl\:mt2 {
    margin-top: 2rem; }
  .xl\:mr2 {
    margin-right: 2rem; }
  .xl\:mb2 {
    margin-bottom: 2rem; }
  .xl\:ml2 {
    margin-left: 2rem; }
  .xl\:mx2 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .xl\:my2 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .xl\:mxn2 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .xl\:p2 {
    padding: 2rem; }
  .xl\:pt2 {
    padding-top: 2rem; }
  .xl\:pr2 {
    padding-right: 2rem; }
  .xl\:pb2 {
    padding-bottom: 2rem; }
  .xl\:pl2 {
    padding-left: 2rem; }
  .xl\:px2 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .xl\:py2 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .xl\:m2_5 {
    margin: 2.5rem; }
  .xl\:mt2_5 {
    margin-top: 2.5rem; }
  .xl\:mr2_5 {
    margin-right: 2.5rem; }
  .xl\:mb2_5 {
    margin-bottom: 2.5rem; }
  .xl\:ml2_5 {
    margin-left: 2.5rem; }
  .xl\:mx2_5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .xl\:my2_5 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .xl\:mxn2_5 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .xl\:p2_5 {
    padding: 2.5rem; }
  .xl\:pt2_5 {
    padding-top: 2.5rem; }
  .xl\:pr2_5 {
    padding-right: 2.5rem; }
  .xl\:pb2_5 {
    padding-bottom: 2.5rem; }
  .xl\:pl2_5 {
    padding-left: 2.5rem; }
  .xl\:px2_5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .xl\:py2_5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .xl\:m3 {
    margin: 3rem; }
  .xl\:mt3 {
    margin-top: 3rem; }
  .xl\:mr3 {
    margin-right: 3rem; }
  .xl\:mb3 {
    margin-bottom: 3rem; }
  .xl\:ml3 {
    margin-left: 3rem; }
  .xl\:mx3 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .xl\:my3 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .xl\:mxn3 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .xl\:p3 {
    padding: 3rem; }
  .xl\:pt3 {
    padding-top: 3rem; }
  .xl\:pr3 {
    padding-right: 3rem; }
  .xl\:pb3 {
    padding-bottom: 3rem; }
  .xl\:pl3 {
    padding-left: 3rem; }
  .xl\:px3 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .xl\:py3 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .xl\:m4 {
    margin: 4rem; }
  .xl\:mt4 {
    margin-top: 4rem; }
  .xl\:mr4 {
    margin-right: 4rem; }
  .xl\:mb4 {
    margin-bottom: 4rem; }
  .xl\:ml4 {
    margin-left: 4rem; }
  .xl\:mx4 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .xl\:my4 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .xl\:mxn4 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .xl\:p4 {
    padding: 4rem; }
  .xl\:pt4 {
    padding-top: 4rem; }
  .xl\:pr4 {
    padding-right: 4rem; }
  .xl\:pb4 {
    padding-bottom: 4rem; }
  .xl\:pl4 {
    padding-left: 4rem; }
  .xl\:px4 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .xl\:py4 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .xl\:m6 {
    margin: 6rem; }
  .xl\:mt6 {
    margin-top: 6rem; }
  .xl\:mr6 {
    margin-right: 6rem; }
  .xl\:mb6 {
    margin-bottom: 6rem; }
  .xl\:ml6 {
    margin-left: 6rem; }
  .xl\:mx6 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .xl\:my6 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .xl\:mxn6 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .xl\:p6 {
    padding: 6rem; }
  .xl\:pt6 {
    padding-top: 6rem; }
  .xl\:pr6 {
    padding-right: 6rem; }
  .xl\:pb6 {
    padding-bottom: 6rem; }
  .xl\:pl6 {
    padding-left: 6rem; }
  .xl\:px6 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .xl\:py6 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .xl\:m8 {
    margin: 8rem; }
  .xl\:mt8 {
    margin-top: 8rem; }
  .xl\:mr8 {
    margin-right: 8rem; }
  .xl\:mb8 {
    margin-bottom: 8rem; }
  .xl\:ml8 {
    margin-left: 8rem; }
  .xl\:mx8 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .xl\:my8 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .xl\:mxn8 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .xl\:p8 {
    padding: 8rem; }
  .xl\:pt8 {
    padding-top: 8rem; }
  .xl\:pr8 {
    padding-right: 8rem; }
  .xl\:pb8 {
    padding-bottom: 8rem; }
  .xl\:pl8 {
    padding-left: 8rem; }
  .xl\:px8 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .xl\:py8 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .xl\:mauto {
    margin: auto; }
  .xl\:mtauto {
    margin-top: auto; }
  .xl\:mrauto {
    margin-right: auto; }
  .xl\:mbauto {
    margin-bottom: auto; }
  .xl\:mlauto {
    margin-left: auto; }
  .xl\:mxauto {
    margin-left: auto;
    margin-right: auto; }
  .xl\:myauto {
    margin-top: auto;
    margin-bottom: auto; }
  .xl\:mxnauto {
    margin-left: -auto;
    margin-right: -auto; }
  .xl\:pauto {
    padding: auto; }
  .xl\:ptauto {
    padding-top: auto; }
  .xl\:prauto {
    padding-right: auto; }
  .xl\:pbauto {
    padding-bottom: auto; }
  .xl\:plauto {
    padding-left: auto; }
  .xl\:pxauto {
    padding-left: auto;
    padding-right: auto; }
  .xl\:pyauto {
    padding-top: auto;
    padding-bottom: auto; }
  .xl\:col-0 {
    width: 0%; }
  .xl\:off-0 {
    margin-left: 0%; }
  .xl\:col-1 {
    width: 8.33333%; }
  .xl\:off-1 {
    margin-left: 8.33333%; }
  .xl\:col-2 {
    width: 16.66667%; }
  .xl\:off-2 {
    margin-left: 16.66667%; }
  .xl\:col-3 {
    width: 25%; }
  .xl\:off-3 {
    margin-left: 25%; }
  .xl\:col-4 {
    width: 33.33333%; }
  .xl\:off-4 {
    margin-left: 33.33333%; }
  .xl\:col-5 {
    width: 41.66667%; }
  .xl\:off-5 {
    margin-left: 41.66667%; }
  .xl\:col-6 {
    width: 50%; }
  .xl\:off-6 {
    margin-left: 50%; }
  .xl\:col-7 {
    width: 58.33333%; }
  .xl\:off-7 {
    margin-left: 58.33333%; }
  .xl\:col-8 {
    width: 66.66667%; }
  .xl\:off-8 {
    margin-left: 66.66667%; }
  .xl\:col-9 {
    width: 75%; }
  .xl\:off-9 {
    margin-left: 75%; }
  .xl\:col-10 {
    width: 83.33333%; }
  .xl\:off-10 {
    margin-left: 83.33333%; }
  .xl\:col-11 {
    width: 91.66667%; }
  .xl\:off-11 {
    margin-left: 91.66667%; }
  .xl\:col-12 {
    width: 100%; }
  .xl\:off-12 {
    margin-left: 100%; } }

*:focus {
  outline-color: #D8D8D8; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit; }

button:hover {
  cursor: pointer; }

.cf-btn-primary {
  background: -webkit-linear-gradient(bottom, #3278C8, #2478C2);
  background: -moz-linear-gradient(bottom, #3278C8, #2478C2);
  background: -o-linear-gradient(bottom, #3278C8, #2478C2);
  background: -ms-linear-gradient(bottom, #3278C8, #2478C2);
  background: linear-gradient(to top, #3278C8, #2478C2); }

.transition, .up-hover, .BlockTitleDescription__description a {
  transition: all 0.2s cubic-bezier(0.25, 0.2, 0, 1); }

.block-width {
  max-width: 67.5rem; }

.up-hover:hover {
  transform: translate3d(0, -0.1rem, 0); }

.up-hover:active {
  transform: translate3d(0, 0, 0); }

.drop-shadow, .BlockTitleDescription__image {
  box-shadow: 0 0 2.5rem 0 rgba(102, 102, 102, 0.4); }

.block-background {
  background-image: linear-gradient(-74deg, #173963 0%, black 100%);
  background-color: black; }

.sans-serif, .block-title, .block-description, .Nav__santu-link {
  font-family: "Inter", Helvetica, sans-serif; }

.inconsolata, .Input__container {
  font-family: "Inconsolata", Courier, monospace; }

.block-title {
  font-size: 1.375rem;
  line-height: 3rem;
  letter-spacing: -1px; }
  @media (min-width: 832px) {
    .block-title {
      font-size: 2.25rem; } }

.block-description {
  font-size: 1rem;
  line-height: 1.65rem;
  letter-spacing: -0.4px; }

.Hero__content {
  max-width: 30rem; }

@media (min-width: 1024px) {
  .Hero__description {
    max-width: 22.5rem; } }

.Hero__button {
  font-family: "Inter", Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  transition: transform 0.1s ease 0s, background-color, border-color;
  padding: 13px 24px 12px;
  border-radius: 25px; }

.Hero__button-primary {
  color: #3E2C0F;
  background: #FFD85F; }
  .Hero__button-primary:hover {
    background: #FFE492; }

.Hero__github-button {
  background-color: transparent;
  border: solid 1px #FFF;
  margin-left: 1rem; }
  .Hero__github-button:hover {
    color: #FFF;
    opacity: 0.75; }

.Hero__github-logo {
  width: 1.125rem;
  margin-right: 0.5rem; }

.Hero__iframe-container {
  height: 16.8rem; }
  @media (min-width: 1024px) {
    .Hero__iframe-container {
      height: 38rem; } }

.Hero__iframe {
  max-width: 37rem;
  height: 28rem;
  border-radius: 0.5rem;
  transform: translate3d(0, 3.5rem, 0);
  background-color: #FFF; }
  @media (min-width: 1024px) {
    .Hero__iframe {
      height: 38rem; } }

.Hero + div {
  margin-top: 16.8rem; }
  @media (min-width: 1024px) {
    .Hero + div {
      margin-top: 3.5rem; } }

.Hero__container-bottom-image {
  width: 140%;
  bottom: -0.5rem;
  left: -20%; }

.Markdown p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 30px; }

.Nav__container {
  height: 8.875rem; }

.Nav__logo {
  width: 340px;
  margin-top: 40px; }

.Nav__santu-link {
  font-size: 0.875rem; }

.Nav__link {
  margin: 0 0.3rem;
  border-bottom: 1px solid #FFF;
  color: #FFF !important; }
  .Nav__link:hover {
    border-color: transparent; }

.Footer {
  height: 20rem;
  font-size: 0.8rem; }
  .Footer__sc-logo {
    max-width: 15rem; }
  .Footer__container-bottom-image {
    width: 140%;
    top: -2px;
    left: -20%; }
  .Footer__container {
    flex-grow: 1; }

.BlockTitleDescription:nth-of-type(even) {
  background-color: #f9f9f9; }

.BlockTitleDescription:nth-of-type(2) {
  padding-top: 16rem; }
  @media (min-width: 1024px) {
    .BlockTitleDescription:nth-of-type(2) {
      padding-top: 6rem; } }

.BlockTitleDescription__container {
  max-width: 35rem; }
  @media (min-width: 1024px) {
    .BlockTitleDescription__container {
      max-width: 67.5rem; } }

.BlockTitleDescription__image {
  border-radius: 0.3rem; }

.BlockTitleDescription__description {
  max-width: 35rem; }
  .BlockTitleDescription__description a {
    border-bottom: solid black 1px; }
    .BlockTitleDescription__description a:hover {
      border-color: transparent; }

.Input__container {
  font-size: 1.125rem;
  border-radius: 0.1rem;
  border-width: 1px;
  border-style: solid;
  height: 4rem;
  max-width: 60%; }
  @media (min-width: 1024px) {
    .Input__container {
      max-width: 100%; } }

.Input__container--full-width {
  max-width: 100%;
  width: 100%; }

.Input__copy-button {
  background-color: transparent; }

.Input__copy-icon {
  width: 1.4rem; }

.Input__copy-indicator--active {
  opacity: 1; }

[data-whatintent='mouse'] *:focus {
  outline: none; }
