.Footer {
  height: 20rem;
  font-size: 0.8rem;

  &__sc-logo {
    max-width: 15rem;
  }
  
  &__container-bottom-image {
    width: 140%;
    top: -2px;
    left: -20%;
  }

  &__container {
    flex-grow: 1;
  }
}
