.sans-serif {
  font-family: $sans-serif;
}

.inconsolata {
  font-family: $inconsolata;
}

.block-title {
  @extend .sans-serif;
  font-size: 1.375rem;
  line-height: 3rem;
  letter-spacing: -1px;
  
  @include media('md-up') {
    font-size: 2.25rem;
  }
}

.block-description {
  @extend .sans-serif;
  font-size: 1rem;
  line-height: 1.65rem;
  letter-spacing: -0.4px;
}