.Nav {
  &__container {
    height: 8.875rem;
  }

  &__logo {
    width: 340px;
    margin-top: 40px;
  }

  &__santu-link {
    @extend .sans-serif;
    font-size: 0.875rem;
  }

  &__link {
    margin: 0 0.3rem;
    border-bottom: 1px solid color('white');
    color: color('white') !important;

    &:hover {
      border-color: transparent;
    }
  }
}
