.Input {
  &__container {
    @extend .inconsolata;
    font-size: 1.125rem;
    border-radius: 0.1rem;
    border-width: 1px;
    border-style: solid;
    height: 4rem;
    max-width: 60%;

    @include media('lg-up') {
      max-width: 100%;
    }
  }

  &__container--full-width {
    max-width: 100%;
    width: 100%;
  }

  &__copy-button {
    background-color: transparent;
  }

  &__copy-icon {
    width: 1.4rem;
  }

  &__copy-indicator--active {
    opacity: 1;
  }
}