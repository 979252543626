.Hero {
  &__content {
    max-width: 30rem;
  }

  &__description {
    @include media('lg-up') {
      max-width: 22.5rem;
    }
  }

  &__button {
    font-family: $sans-serif;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    transition: transform 0.1s ease 0s, background-color, border-color;
    padding: 13px 24px 12px;
    border-radius: 25px;
  }

  &__button-primary {
    color: color('brown');
    background: color('yellow');

    &:hover {
      background: color('yellow-light');
    }
  }

  &__github-button {
    background-color: transparent;
    border: solid 1px color('white');
    margin-left: 1rem;

    &:hover {
      color: color('white');
      opacity: 0.75;
    }
  }

  &__github-logo {
    width: 1.125rem;
    margin-right: 0.5rem;
  }

  &__iframe-container {
    height: $iframe-height-mobile * 0.6;

    @include media('lg-up') {
      height: $iframe-height-desktop;
    }
  }

  &__iframe {
    max-width: 37rem;
    height: $iframe-height-mobile;
    border-radius: 0.5rem;
    transform: translate3d(0, 3.5rem, 0);
    background-color: color('white');

    @include media('lg-up') {
      height: $iframe-height-desktop;
    }
  }

  & + div {
    margin-top: $iframe-height-mobile * 0.6;

    @include media('lg-up') {
      margin-top: 3.5rem;
    }
  }

  &__container-bottom-image {
    width: 140%;
    bottom: -0.5rem;
    left: -20%;
  }
}
